import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { ngxDataTableConfig } from "app/shared/components/datatables/ngx-shared-table/ngx-models/ngx-datatable-config.model";

export const INVOICE_DATATABLE_CONFIG: ngxDataTableConfig = {
  columnMode: ColumnMode.force,
  externalPaging: true,
  externalSorting: true,
  footerHeight: 56,
  headerHeight: 35,
  pageCount: 10,
  pageIndex: 0,
  reorderable: false,
  totalItems: 0,
  scrollbarH: true,
  rowHeight: 35,
  selectionType: SelectionType.single,
  sorts: [{ prop: "id", direction: "desc" }],
  messages: {
    emptyMessage: "noData",
    totalMessage: "",
    selectedMessage: "",
  },
  unite: "unite.bill",
  selectionCheckbox: false,
  selectedRow: [],
  filtersList: [
      { value: "search", name: "filters.inputs.search", checked: true },
      {
        value: "offlineReference",
        name: "filters.inputs.offlineReferenceSearch",
        checked: true,
      },
      { value: "client", name: "filters.inputs.client", checked: true },
      { value: "provider", name: "filters.inputs.provider", checked: true },
      { value: "period", name: "filters.inputs.createAt", checked: true },
      { value: "user", name: "filters.inputs.createBy", checked: false },
      { value: "tag", name: "filters.inputs.tag", checked: false },
      { value: "state", name: "filters.inputs.state", checked: false },
      { value: "commune", name: "filters.inputs.commune", checked: false },
      { value: "ttcMax", name: "filters.inputs.ttc", checked: false },
      { value: "product", name: "filters.inputs.product", checked: false },
    ],
};
